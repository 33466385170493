import React from "react";
import { graphql } from "gatsby";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

import phone from "../images/15.0 Meet the team/Profile pages/phone.svg";
import email from "../images/15.0 Meet the team/Profile pages/email-solid.svg";
import linkedin from "../images/15.0 Meet the team/Profile pages/linkedin.svg";

export const query = graphql`
  query TeamMemberTemplateQuery($id: String!) {
    teamMember: sanityTeamMember(id: { eq: $id }) {
      id
      name
      seoTitle
      metaDescription
      headshot {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 325)
        }
      }
      title
      emailAddress
      phoneNumber
      badges {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 100)
        }
      }
      _rawBio
    }
  }
`;

const StyledContent = styled.div`
  h2 {
    ${tw`font-bold text-2xl mb-4`}
  }
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const Template = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title={data.teamMember.seoTitle && data.teamMember.seoTitle}
        description={
          data.teamMember.metaDescription && data.teamMember.metaDescription
        }
        // openGraphImage={data.teamMember.openGraphImage && data.teamMember.openGraphImage.asset.url}
        // twitterOpenGraphImage={
        //   data.teamMember.twitterCardImage && data.teamMember.twitterCardImage.asset.url
        // }
      />

      <section className="mb-20 lg:mb-32">
        <div className="container">
          <div className="grid items-start gap-y-8 lg:grid-cols-12">
            <div className="relative -mx-4 hidden px-4 pt-16 pb-18 before:absolute before:left-0 before:top-0 before:hidden before:h-full before:w-full before:-translate-x-full before:bg-blue lg:col-span-4 lg:col-start-1 lg:mx-0 lg:block lg:pl-0 lg:pr-24 lg:before:block">
              <div className="absolute left-0 top-0 h-full w-full rounded-b-3xl bg-blue lg:rounded-r-[90px] lg:rounded-bl-none"></div>
              <div className="relative">
                {data.teamMember.headshot && (
                  <div className="lg:mb-6">
                    <GatsbyImage
                      image={data.teamMember.headshot.asset.gatsbyImageData}
                      alt={data.teamMember.name}
                      loading="eager"
                      className="w-[163px] lg:w-[325px]"
                    />
                  </div>
                )}

                <div className="space-y-5">
                  {data.teamMember.emailAddress && (
                    <div className="flex items-center space-x-3">
                      <img src={email} width={24} />
                      <a
                        href={`mailto:${data.teamMember.emailAddress}`}
                        className="font-semibold text-snow no-underline"
                      >
                        {data.teamMember.emailAddress}
                      </a>
                    </div>
                  )}

                  {data.teamMember.phoneNumber && (
                    <div className="flex items-center space-x-3">
                      <img src={phone} width={24} />
                      <a
                        href={`tel:${data.teamMember.phoneNumber
                          .replace(/[()]/g, "")
                          .replace(/ /g, "-")}`}
                        className="font-semibold text-snow no-underline"
                      >
                        {data.teamMember.phoneNumber}
                      </a>
                    </div>
                  )}

                  {data.teamMember.linkedin && (
                    <div className="flex items-center space-x-3">
                      <img src={linkedin} width={24} />
                      <a
                        href={data.teamMember.linkedin}
                        className="font-semibold text-snow no-underline"
                      >
                        LinkedIn
                      </a>
                    </div>
                  )}
                </div>

                {data.teamMember.badges.length > 0 && (
                  <div className="mt-6 border-t border-gray-300 pt-6">
                    <div
                      className={`grid items-center justify-start gap-x-3 lg:justify-start lg:gap-x-4 ${
                        data.teamMember.badges.length > 1 && "grid-cols-2"
                      }`}
                    >
                      {data.teamMember.badges.map((education, i) => {
                        return (
                          <GatsbyImage
                            image={education.asset.gatsbyImageData}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="lg:col-span-8 lg:col-end-13 lg:pt-16 lg:pl-10">
              <div className="relative -mx-4 px-4 pt-12 pb-9 lg:mx-0 lg:p-0">
                <div className="absolute left-0 top-0 h-full w-full rounded-b-3xl bg-blue lg:rounded-r-[90px] lg:rounded-bl-none lg:bg-transparent"></div>

                <div className="relative mb-8 lg:mb-0">
                  <h1 className="mb-3 text-white lg:mb-5 lg:text-typography-heading">
                    {data.teamMember.name}
                  </h1>
                  {data.teamMember.title && (
                    <div className="font-heading text-sm font-bold uppercase text-gray-50 lg:text-typography-body">
                      {data.teamMember.title}
                    </div>
                  )}
                </div>

                <div className="lg:hidden">
                  <div className="relative">
                    {data.teamMember.headshot && (
                      <div className="mb-6 border-b border-gray-300 pb-6">
                        <GatsbyImage
                          image={data.teamMember.headshot.asset.gatsbyImageData}
                          alt={data.teamMember.name}
                          loading="eager"
                          className="w-[163px] lg:w-[325px]"
                        />
                      </div>
                    )}

                    <div className="space-y-5">
                      {data.teamMember.emailAddress && (
                        <div className="flex items-center space-x-3">
                          <img src={email} width={24} />
                          <a
                            href={`mailto:${data.teamMember.emailAddress}`}
                            className="font-semibold text-snow no-underline"
                          >
                            {data.teamMember.emailAddress}
                          </a>
                        </div>
                      )}

                      {data.teamMember.phoneNumber && (
                        <div className="flex items-center space-x-3">
                          <img src={phone} width={24} />
                          <a
                            href={`tel:${data.teamMember.phoneNumber
                              .replace(/[()]/g, "")
                              .replace(/ /g, "-")}`}
                            className="font-semibold text-snow no-underline"
                          >
                            {data.teamMember.phoneNumber}
                          </a>
                        </div>
                      )}

                      {data.teamMember.phoneNumber && (
                        <div className="flex items-center space-x-3">
                          <img src={linkedin} width={24} />
                          <a
                            href={data.teamMember.linkedin}
                            className="font-semibold text-snow no-underline"
                          >
                            LinkedIn
                          </a>
                        </div>
                      )}
                    </div>

                    {data.teamMember.badges.length > 0 && (
                      <div className="mt-6">
                        <div
                          className={`grid items-center justify-start gap-x-3 lg:justify-start lg:gap-x-4 ${
                            data.teamMember.badges.length > 1 && "grid-cols-2"
                          }`}
                        >
                          {data.teamMember.badges.map((education, i) => {
                            return (
                              <GatsbyImage
                                image={education.asset.gatsbyImageData}
                                className="w-[67px] md:w-[100px]"
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {data.teamMember._rawBio && (
                <div className="mt-10 lg:mt-16">
                  <StyledContent>
                    <PortableText blocks={data.teamMember._rawBio} />
                  </StyledContent>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Template;
